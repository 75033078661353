import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import * as Sentry from "@sentry/vue";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy);

        Sentry.init({
            app,
            dsn: "https://02760ea318ce3998f7537e89284407af@o4507866132643840.ingest.us.sentry.io/4508373759164416",
            // dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                Sentry.browserTracingIntegration({ Ziggy }),
                Sentry.replayIntegration(),
            ],
        });

        app.mount(el);
        return app;
    },
    progress: {
        color: "#4B5563",
    },
});
